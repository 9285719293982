import { H1, Link } from '@dnb/eufemia';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { OpenAPIV3 } from 'openapi-types';
import { Fragment } from 'react';

import { SecurityTypeMap } from '@/pages/api-documentation/constants/types';

import { H3S } from '@/pages/api-documentation/styles/Api.Reference.styles';
import { SchemaWrapper } from '@/pages/api-documentation/styles/Api.styles';

type SecuritySchemaProps = {
  data: {
    [p: string]: OpenAPIV3.ReferenceObject | OpenAPIV3.SecuritySchemeObject;
  };
};

const oAuthFlowNames: Record<string, string> = {
  implicit: 'Implicit',
  password: 'Resource Owner Password',
  clientCredentials: 'Client Credentials',
  authorizationCode: 'Authorization Code',
};

const SecuritySchema = ({ data }: SecuritySchemaProps) => {
  return (
    <>
      <H1 id="security-schemes">Security Schemes</H1>
      {Object.entries(data).map(([type, schema]) => {
        return hasOwnProperty(schema, 'type') ? (
          <Fragment key={`security-schemes/${type}`}>
            <H3S id={`security-schemes/${type}`}>{type}</H3S>
            <SchemaWrapper>
              <ul className="schema-item-section">
                <li>
                  <div className="schema-item">
                    <div className="id">
                      <strong>Type:</strong>
                    </div>
                    <div className="desc">{SecurityTypeMap[schema.type]}</div>
                  </div>
                </li>
                <li>
                  {(type.toLowerCase() === 'http' && (
                    <div className="schema-item">
                      <div className="id">
                        <strong>Header parameter name:</strong>
                      </div>
                      <div className="desc" />
                    </div>
                  )) ||
                    (hasOwnProperty(schema, 'in') && ( // apiKey
                      <div className="schema-item">
                        <div className="id">
                          <strong>Header parameter name:</strong>
                        </div>
                        <div className="desc">
                          <div className="dnb-code">{schema.name}</div>
                        </div>
                      </div>
                    )) ||
                    (hasOwnProperty(schema, 'openIdConnectUrl') && (
                      <div className="schema-item">
                        <div className="id">
                          <strong>OpenID Connect URL:</strong>
                        </div>
                        <div className="desc">
                          <Link to={schema.openIdConnectUrl}>
                            {schema.openIdConnectUrl}
                          </Link>
                        </div>
                      </div>
                    )) ||
                    (hasOwnProperty(schema, 'flows') && (
                      <>
                        {Object.entries(schema.flows).map(([key, flow]) => {
                          // oAuth schema
                          return (
                            <div
                              className="schema-item"
                              key={`oauth-${oAuthFlowNames[key]}`}
                            >
                              <div className="id">
                                <strong>{oAuthFlowNames[key]} flow:</strong>
                              </div>
                              <div className="desc">
                                {hasOwnProperty(flow, 'authorizationUrl') && (
                                  <div className="line">
                                    Authorization URL:
                                    <br />
                                    <code className="dnb-code">
                                      {flow.authorizationUrl}
                                    </code>
                                  </div>
                                )}
                                {hasOwnProperty(flow, 'tokenUrl') && (
                                  <div className="line">
                                    Token URL:
                                    <br />
                                    <code className="dnb-code">
                                      {flow.tokenUrl}
                                    </code>
                                  </div>
                                )}
                                {flow.refreshUrl && (
                                  <>
                                    Refresh URL:
                                    <br />
                                    <code className="dnb-code">
                                      {flow.refreshUrl}
                                    </code>
                                  </>
                                )}
                                {flow.scopes && (
                                  <div className="line">
                                    Scopes: <br />
                                    {Object.keys(flow.scopes).map(
                                      (scopeName) => (
                                        <>
                                          <code className="dnb-code">
                                            {scopeName}
                                          </code>{' '}
                                          - {flow.scopes[scopeName]}
                                          <br />
                                        </>
                                      ),
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ))}
                </li>
              </ul>
            </SchemaWrapper>
          </Fragment>
        ) : null;
      })}
    </>
  );
};

export default SecuritySchema;
