import {
  Accordion,
  Button,
  Card,
  Dialog,
  Flex,
  FormStatus,
  H3,
  H4,
  Icon,
  Li,
  P,
  Section,
  Span,
  Ul,
} from '@dnb/eufemia';
import {
  check as CheckIcon,
  close as CloseIcon,
  list_medium as ListIcon,
  play as PlayIcon,
  view_medium as ViewIcon,
} from '@dnb/eufemia/icons';
import type {
  ApiDto,
  ApiWithStatusAndScopesDto,
} from '@portals/shared/portal/ApiDto';
import { SpectralDiagnosticsDto } from '@portals/shared/portal/ReleaseDto';
import { useAsync, useLocalStorage } from '@portals/shared-frontend/hooks';
import { anyTrue } from '@portals/shared-frontend/utils';
import { ApiError as ApiErrorType } from '@portals/shared-frontend/utils/ApiError';
import type { OpenAPIV3 } from 'openapi-types';
import { type ReactNode, useMemo, useState } from 'react';
import useSWR from 'swr';

import { publishApiSlackNotification } from '@/api/app';
import LoadingModal from '@/components/LoadingModal';
import Toast from '@/components/Toast';
import { request } from '@/request';

import {
  type ApiError,
  type ApiErrorKeys,
  type ApiValidationRequirement,
  validationData,
  type ValidationKey,
} from './helpers';

import style from './index.module.css';

type PublishApiSectionProps = {
  api: ApiWithStatusAndScopesDto;
  appId: string;
};
type ApiMetadata = {
  guide: string | null;
  reference: OpenAPIV3.Document | null;
};
export default function PublishApiSection({
  api,
  appId,
}: PublishApiSectionProps) {
  const [state, setState] = useLocalStorage(`publish-${api.slug}`, false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [successMessage, setSuccessMessage] = useState<null | string>(null);

  const { data: apis, isValidating: loadingApis } = useSWR<ApiDto[]>('/api');
  const { data: apiMetadata, isValidating: loadingApiMetadata } =
    useSWR<ApiMetadata>(
      `/api/${api.slug}/environments/@default/releases/@latest/data` ?? null,
    );
  const {
    data: spectralDiagnostics,
    isValidating: spectralDiagnosticsLoading,
  } = useSWR<SpectralDiagnosticsDto | null>(
    `/api/${api.slug}/environments/@default/releases/@latest/spectral-diagnostics`,
    async (url) => {
      return request<SpectralDiagnosticsDto>(url, 'get');
    },
    {
      //Ignore any error in spectral service. The ui will show not avialable
      onError: () => {},
    },
  );

  const onPublish = useAsync(async () => {
    try {
      await publishApiSlackNotification(api.id, appId);
      setSuccessMessage('API successfully sent to review.');
    } catch (error) {
      if (ApiErrorType.isApiError(error)) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('Something went wrong. Please try again later.');
      }
    }
  }, [api.id, appId]);

  const data = useMemo(() => {
    if (!apis) {
      return [];
    }

    return validationData(
      api,
      apis,
      apiMetadata?.reference,
      apiMetadata?.guide ?? null,
      spectralDiagnostics || null,
    );
  }, [
    api,
    apiMetadata?.guide,
    apiMetadata?.reference,
    apis,
    spectralDiagnostics,
  ]);

  const isLoading = anyTrue(
    loadingApis,
    loadingApiMetadata,
    spectralDiagnosticsLoading,
  );
  if (isLoading) {
    return <LoadingModal />;
  }

  return state ? (
    <Section
      backgroundColor="black-3"
      innerSpace={{
        left: 'large',
        right: 'large',
      }}
    >
      {onPublish.waiting && <LoadingModal />}
      <H3>
        <Span right="x-small">
          <ListIcon />
        </Span>
        Ready to publish your API?
      </H3>
      <Card className={style['Card']} top="small">
        <Accordion expanded icon_position="right" variant="plain">
          <Accordion.Header bottom={0}>
            <H4>API review process</H4>
          </Accordion.Header>

          <Accordion.Content top={0}>
            {data.map((item, index) => (
              <ValidateSection
                additionalInformation={item.additionalInformation}
                data={item.inputData}
                errorKey={item.errorKey}
                errors={item.errors}
                key={index}
                title={item.title}
              />
            ))}
          </Accordion.Content>
        </Accordion>
        <Dialog
          confirmText="Start review"
          description="Make sure that the API meets the requirements listed in the checklist before sending to the review process"
          icon={ViewIcon}
          left="small"
          onConfirm={({ close }) => {
            onPublish.execute();
            close();
          }}
          title="Are you sure you want to send the API to be reviewed?"
          triggerAttributes={{ text: 'Send API to approval process' }}
          variant="confirmation"
        />

        {errorMessage && <FormStatus left="small" text={errorMessage} />}
        {successMessage && (
          <Toast message={successMessage} position="bottom" variant="success" />
        )}
      </Card>
    </Section>
  ) : (
    <StartSection onClick={() => setState(true)} />
  );
}

type ValidateProps = {
  data: ApiValidationRequirement;
  title: string;
  errorKey: ValidationKey;
  errors: ApiError;
  additionalInformation?: ReactNode;
};
function ValidateSection({
  errorKey,
  data,
  title,
  errors,
  additionalInformation,
}: ValidateProps) {
  const hasErrors = !!errors[errorKey];

  return (
    <Accordion
      bottom="small"
      className={style['Accordion']}
      expanded={hasErrors}
      icon_position="right"
      variant="plain"
    >
      <Card className={style['Card']}>
        <Accordion.Header>
          {hasErrors ? (
            <P>{title}</P>
          ) : (
            <P>
              <Icon color="var(--color-success-green)" icon={CheckIcon} />{' '}
              {title}
            </P>
          )}
        </Accordion.Header>
        <Accordion.Content bottom="small" left="small" top="0">
          {errors && !!errors[errorKey] && (
            <FormStatus
              bottom="small"
              state="warn"
              text={
                <>
                  <H4>{title} format</H4>
                  <P>
                    Remember to make the following changes before sending the
                    API to review.
                  </P>
                  <Ul>
                    {Object.values(errors[errorKey]!).map((value) => (
                      <Li key={value}>{value}</Li>
                    ))}
                  </Ul>
                  {additionalInformation}
                </>
              }
              title={title}
            />
          )}
          <H4>Checklist</H4>
          {Object.keys(data).map((item, index) => {
            const error = errors[errorKey];
            return (
              <Flex.Horizontal
                key={`${errorKey}-${index}`}
                top="small"
                wrap={false}
              >
                <P>
                  {error && !!error[item as ApiErrorKeys] ? (
                    <Icon color="var(--color-fire-red)" icon={CloseIcon} />
                  ) : (
                    <Icon color="var(--color-success-green)" icon={CheckIcon} />
                  )}
                </P>
                <P>{data[item as ApiErrorKeys]}</P>
              </Flex.Horizontal>
            );
          })}
        </Accordion.Content>
      </Card>
    </Accordion>
  );
}

type StartProps = {
  onClick: () => void;
};
function StartSection({ onClick }: StartProps) {
  return (
    <Section
      backgroundColor="black-3"
      innerSpace={{ left: 'xx-large', right: 'xx-large' }}
    >
      <Flex.Vertical className={style['PublishApiSection']}>
        <H3>Is your API ready to be viewed by others?</H3>

        <P top="medium">
          Before we can start the publishing process we need to make sure your
          API meets a set of requirements. By clicking the button below a
          checklist will appear guiding you to make the necessary changes. Be
          aware that these checks will run against your default environment and
          latest release.
        </P>

        <Button
          icon={PlayIcon}
          icon_position="left"
          on_click={onClick}
          top="medium"
          variant="secondary"
        >
          Start publishing process
        </Button>
      </Flex.Vertical>
    </Section>
  );
}
