import { Dialog, Div, P, Space, Span } from '@dnb/eufemia';
import { trash as TrashIcon } from '@dnb/eufemia/icons';
import type { ClientDto } from '@portals/shared/portal/ClientDto';

import Card from '@/components/Card';

import style from './index.module.css';

export type ClientProps = {
  isAdmin: boolean;
  client: ClientDto;
  onDeleteClicked: () => void;
  onDeleteFromPortalClicked?: (() => void) | null;
};

export default function Client({
  isAdmin,
  client,
  onDeleteClicked,
  onDeleteFromPortalClicked,
}: ClientProps): JSX.Element | null {
  return (
    <Card className={style['ClientTile']} key={client.clientId}>
      <Space className={style['ClientTileContent']}>
        <Space>
          <P bottom="x-small">{client.name || 'Client ID'}</P>
          <Span className={style['Subtext']}>{client.clientId}</Span>
        </Space>
      </Space>

      {isAdmin && (
        <Div className={style['ClientTileContent']}>
          <Dialog
            confirmText="Delete"
            confirmType="warning"
            declineText="Cancel"
            description="This action cannot be undone."
            icon={TrashIcon}
            onConfirm={({ close }) => {
              close();
              onDeleteClicked();
            }}
            title="Are you sure you want to delete this?"
            triggerAttributes={{
              text: 'Delete ',
              icon: TrashIcon,
              variant: 'tertiary',
            }}
            variant="confirmation"
          />
          {onDeleteFromPortalClicked && (
            <Dialog
              confirmText="Delete from portal"
              confirmType="warning"
              declineText="Cancel"
              description="This action cannot be undone."
              icon={TrashIcon}
              onConfirm={({ close }) => {
                close();
                onDeleteFromPortalClicked();
              }}
              title="Are you sure you want to delete this client from portal? It will still keep the client in ciam system."
              triggerAttributes={{
                text: 'Delete from portal',
                icon: TrashIcon,
                variant: 'tertiary',
              }}
              variant="confirmation"
            />
          )}
        </Div>
      )}
    </Card>
  );
}
