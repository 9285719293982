import { Accordion, Badge, Dialog, Icon, P, Space, Span } from '@dnb/eufemia';
import {
  check as CheckIcon,
  padlock as CrossIcon,
  pension as PensionIcon,
  trash as TrashIcon,
} from '@dnb/eufemia/icons';
import type { SpacingElementProps } from '@dnb/eufemia/shared/types';
import type { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import { groupBy } from '@portals/shared-frontend/utils';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Card from '@/components/Card';
import Divider from '@/components/Divider';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import BottomButtons from '@/pages/profile/apps/application/components/Entitlement/components/BottomButtons';

import EditScopes from '../../EditScopes';
import RequestFormModal from '../../RequestFormModal/RequestFormModal';
import RequestProductionAccessModal from '../../RequestProductionAccessModal';
import RequestApiAccessFormModal from '../../RequstApiAccessFormModal/RequestApiAccessFormModal';
import ScopeCard from '../ScopeCard';

import style from './index.module.css';

type EntitlementProps = SpacingElementProps & {
  isAdmin: boolean;
  entitlement: CurrentApiAndScopeEntitlementsDto;
  onDetachApi: (appId: string, apiId: string) => void;
  hasPendingRequest: boolean;
  hasDeniedRequest: boolean;
};

const accessTypes = [
  { title: 'No access, need to request access', icon: <CrossIcon /> },
  { title: 'Test', icon: <PensionIcon /> },
  { title: 'Live', icon: <CheckIcon /> },
];

const DEVELOPER_PORTAL_API_SLUG = 'developer-portal-api';

export default function Entitlement({
  isAdmin,
  entitlement,
  onDetachApi,
  hasPendingRequest,
  hasDeniedRequest,
  ...props
}: EntitlementProps): JSX.Element {
  const { id: appId } = useParams();
  const [showRequestLiveModeModal, setShowRequestLiveModeModal] =
    useState(false);

  const [showEditScopeModal, setShowEditScopesModal] = useState(false);
  const { featureFlags } = useFeatureFlags();

  const hasLiveAccess = entitlement.entitlements.some(
    ({ liveMode }) => liveMode,
  );

  const accessType = entitlement.entitlements.some(({ liveMode }) => liveMode)
    ? 2
    : entitlement.api.approvalStage === 'test' &&
      entitlement?.scopeEntitlements.length === 0
    ? 0
    : 1;

  const isScopeInSync = useMemo(() => {
    const scopes = entitlement?.scopeEntitlements;
    // Create a Set to keep track of the unique scope names
    const uniqueScopes = new Set(scopes.map((scope) => scope.scopeName));

    // Iterate over the unique scope names
    for (const scopeName of uniqueScopes) {
      // Check if both "liveMode": true and "liveMode": false exist for the scopeName
      const hasTrue = scopes.some(
        (scope) => scope.scopeName === scopeName && scope.liveMode,
      );
      const hasFalse = scopes.some(
        (scope) => scope.scopeName === scopeName && !scope.liveMode,
      );

      // If either "liveMode": true or "liveMode": false is missing, return false
      if (!hasTrue || !hasFalse) {
        return false;
      }
    }

    // If all scope names have both "liveMode": true and "liveMode": false, return true
    return true;
  }, [entitlement?.scopeEntitlements]);

  // Group by name, so we do not get duplicates entitlements mapped
  const scopeEntitlementsByScopeName = groupBy(
    entitlement?.scopeEntitlements,
    'scopeName',
  );

  function ServiceNowFormComponent() {
    if (
      featureFlags.ENABLE_SERVICE_NOW_API_INTEGRATION &&
      entitlement.api.enableServiceNowRequest
    ) {
      return (
        <RequestApiAccessFormModal
          entitlement={entitlement}
          onClose={() => {
            setShowRequestLiveModeModal(false);
            window.scrollTo(0, 0);
          }}
        />
      );
    }
    return (
      <RequestFormModal
        entitlement={entitlement}
        onClose={() => {
          setShowRequestLiveModeModal(false);
          window.scrollTo(0, 0);
        }}
      />
    );
  }

  return (
    <Card {...props}>
      <Accordion icon_position="right" variant="plain">
        <Accordion.Header>
          <Space className={style['AccordionTitle']}>
            <P bold>
              {entitlement.api.name}
              {hasPendingRequest && (
                <Badge content="Access pending" left="small" />
              )}
            </P>

            <P className={style['AccordionTitle--Status']} size="small">
              {accessTypes[accessType].title}
              <Span
                className={classNames(
                  style['AccordionTitleIcon'],
                  style[
                    `AccordionTitleIcon-${
                      hasLiveAccess ? 'production' : 'development'
                    }`
                  ],
                )}
              >
                <Icon icon={accessTypes[accessType].icon} />
              </Span>
            </P>
          </Space>
        </Accordion.Header>

        <Accordion.Content left="small" right="small">
          <P bottom="medium">{entitlement.api.description}</P>
          {entitlement?.scopeEntitlements.length === 0 && accessType !== 0 && (
            <ScopeCard
              environments={hasLiveAccess ? ['Test', 'Live'] : ['Test']}
              scopeEntitlement={{
                scopeName: `Full access`,
                scopeDescription: `Full access granted`,
                liveMode: hasLiveAccess,
                scopeId: '',
              }}
            />
          )}

          {Object.keys(scopeEntitlementsByScopeName).map((scopeName) => {
            const entitlements = scopeEntitlementsByScopeName[scopeName];
            const environments = entitlements.map((ent) =>
              ent.liveMode ? 'Live' : 'Test',
            );
            const scopeEntitlement = entitlements[0];
            return (
              <Space bottom="small" key={scopeName}>
                <ScopeCard
                  environments={environments}
                  scopeEntitlement={scopeEntitlement}
                />
              </Space>
            );
          })}

          <Space bottom="medium" top="medium">
            <Divider />
          </Space>

          {isAdmin && (
            <>
              <Space className={style['ContentContainer-Buttons']}>
                {entitlement.api.slug !== DEVELOPER_PORTAL_API_SLUG && (
                  <Dialog
                    confirmText="Detach"
                    description={
                      <>
                        This will remove the access to this API.{' '}
                        <b>
                          If you have any pending requests to this API, they
                          will be canceled.
                        </b>
                      </>
                    }
                    onConfirm={async () => {
                      if (appId) {
                        onDetachApi(appId, entitlement.api.id);
                      }
                    }}
                    title="Are you sure you want to detach this API?"
                    triggerAttributes={{
                      text: 'Detach API',
                      icon: TrashIcon,
                      icon_position: 'left',
                      variant: 'tertiary',
                    }}
                    variant="confirmation"
                  />
                )}

                <BottomButtons
                  accessType={accessType}
                  entitlement={entitlement}
                  hasDeniedRequest={hasDeniedRequest}
                  hasLiveAccess={hasLiveAccess}
                  hasPendingRequest={hasPendingRequest}
                  isScopeInSync={isScopeInSync}
                  setShowEditScopesModal={setShowEditScopesModal}
                  setShowRequestLiveModeModal={setShowRequestLiveModeModal}
                />
              </Space>
              {showEditScopeModal && (
                <EditScopes
                  entitlement={entitlement}
                  onClose={() => setShowEditScopesModal(false)}
                />
              )}

              {showRequestLiveModeModal &&
                (entitlement.api.accessTypes?.includes(
                  'O_AUTH_SYSTEM_TO_SYSTEM',
                ) &&
                Boolean(
                  entitlement.scopeEntitlements.length > 0 || accessType === 0,
                ) ? (
                  <ServiceNowFormComponent />
                ) : (
                  <RequestProductionAccessModal
                    entitlement={entitlement}
                    onClose={() => {
                      setShowRequestLiveModeModal(false);
                      window.scrollTo(0, 0);
                    }}
                  />
                ))}
            </>
          )}
        </Accordion.Content>
      </Accordion>
    </Card>
  );
}
