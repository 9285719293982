/**
 * Classificaton icon
 */

import { Avatar, Div, Tooltip, VisuallyHidden } from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/portal/ApiDto';

import { CLASSIFICATION_DESCRIPTIONS } from './content';

import style from './ApiClassification.module.css';

const CLASSIFICATIION_ICONS = {
  system: 'S',
  domain: 'D',
  technical: 'T',
  experience: 'E',
  partner: 'P',
  public: '',
};

interface ApiClassificationProps {
  classification: ApiDto['classification'];
}

export function ApiClassification({
  classification,
}: ApiClassificationProps): JSX.Element | null {
  if (!CLASSIFICATIION_ICONS[classification]) {
    return null;
  }

  return (
    <Div>
      <Tooltip
        arrow="left"
        hideDelay={0}
        noAnimation
        showDelay={100}
        size="large"
        targetElement={
          <div>
            <Avatar right="small">
              <VisuallyHidden>API classification</VisuallyHidden>
              {CLASSIFICATIION_ICONS[classification]}
            </Avatar>
          </div>
        }
      >
        <span className={style['ApiClassification-tooltip']}>
          {CLASSIFICATION_DESCRIPTIONS[classification]}
        </span>
      </Tooltip>
    </Div>
  );
}
