import { Code } from '@dnb/eufemia';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { OpenAPIV3 } from 'openapi-types';

import { SecurityTypeMap } from '@/pages/api-documentation/constants/types';

import {
  H3S,
  H4S,
} from '@/pages/api-documentation/styles/Api.Reference.styles';
import { SchemaWrapper } from '@/pages/api-documentation/styles/Api.styles';

type SecurityProps = {
  data: {
    security: OpenAPIV3.SecurityRequirementObject[] | undefined;
    itemSecurity: OpenAPIV3.SecurityRequirementObject[] | undefined;
    schemas:
      | {
          [p: string]:
            | OpenAPIV3.SecuritySchemeObject
            | OpenAPIV3.ReferenceObject;
        }
      | undefined;
  };
};

const Security = ({ data }: SecurityProps) => {
  const { security, schemas, itemSecurity } = data;

  return (
    <div>
      {(Boolean(itemSecurity && itemSecurity.length > 0) && (
        <div>
          <H3S>Authentication</H3S>
          <SchemaWrapper>
            <ul className="schema-item-section">
              {itemSecurity?.map((key) => (
                <>
                  {Object.keys(key).map((item) => {
                    const orgSchema = schemas ? schemas[item] : null;

                    return orgSchema && hasOwnProperty(orgSchema, 'type') ? (
                      <li key={`item-schema-security${Object.keys(key)}`}>
                        <div className="schema-item">
                          <div className="id">
                            <strong>{item}</strong>
                            <br />
                            {SecurityTypeMap[orgSchema.type]}
                          </div>
                          <div className="desc">
                            {hasOwnProperty(orgSchema, 'in') && ( // apiKey
                              <>
                                <strong>Header:</strong>
                                <div className="dnb-code">{orgSchema.name}</div>
                              </>
                            )}

                            {key[item].length > 0 && (
                              <>
                                <strong>Scopes:</strong>
                                <br />
                                <Code>{key[item]}</Code>
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                    ) : null;
                  })}
                </>
              ))}
            </ul>
          </SchemaWrapper>
        </div>
      )) ||
        (security !== undefined && (
          <div>
            <H3S>Authentication</H3S>
            {security?.map((item, i) => {
              return (
                <>
                  {security.length !== 1 && <H4S>Method {i + 1}:</H4S>}
                  <SchemaWrapper>
                    <ul className="schema-item-section">
                      {schemas && (
                        <>
                          {Object.keys(item).map((key) => {
                            const schemaData = schemas[
                              key
                            ] as OpenAPIV3.SecuritySchemeObject;

                            return (
                              <li key={`schema-item${key}`}>
                                <div className="schema-item">
                                  <div className="id">
                                    <strong>{key}</strong>
                                    <br />
                                    {SecurityTypeMap[schemaData.type]}
                                  </div>
                                  <div className="desc">
                                    {item[key].length > 0 && (
                                      <>
                                        <strong>Scopes:</strong>{' '}
                                        {item[key].map((sc) => (
                                          <>
                                            <span className="dnb-code">
                                              {sc}
                                            </span>
                                            &nbsp;
                                          </>
                                        ))}
                                      </>
                                    )}
                                    {schemaData.type === 'apiKey' && (
                                      <>
                                        <strong>Header:</strong>
                                        <span className="dnb-code">
                                          {schemaData.name}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </SchemaWrapper>
                </>
              );
            })}
          </div>
        ))}
    </div>
  );
};

export default Security;
