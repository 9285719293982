import { Flex, Input, ToggleButton } from '@dnb/eufemia';
import { useEffect, useState } from 'react';

import type { SimpleEvent } from '@/pages/events/EventModels';

interface EventFilterProps {
  events: SimpleEvent[];
  onChange: (filteredEvents: SimpleEvent[]) => void;
}

enum EventType {
  Domain = 'domain',
  System = 'system',
  Experience = 'experience',
}

export default function EventFilter({ events, onChange }: EventFilterProps) {
  const [eventType, setEventType] = useState<EventType>();
  const [searchQuery, setSearchQuery] = useState<string>();

  useEffect(() => {
    if (!eventType && !searchQuery) {
      onChange(events);
      return;
    }
    const filteredEvents = events.filter((event) => {
      if (eventType && !(event.type === eventType)) return false;
      return searchQuery ? event.name.includes(searchQuery) : true;
    });
    onChange(filteredEvents);
  }, [events, onChange, searchQuery, eventType]);

  return (
    <Flex.Stack spacing="large">
      <ToggleButton.Group
        on_change={({ value }) =>
          setEventType(value === 'all' ? undefined : value)
        }
        value={eventType ?? 'all'}
      >
        <ToggleButton text="All" value="all" />
        <ToggleButton text="Domain" value={EventType.Domain} />
        <ToggleButton text="Experience" value={EventType.Experience} />
        <ToggleButton text="System" value={EventType.System} />
      </ToggleButton.Group>
      <Input
        bottom="small"
        clear
        icon="loupe"
        on_change={({ value }) => setSearchQuery(value)}
        placeholder="Search"
        value={searchQuery}
      />
    </Flex.Stack>
  );
}
