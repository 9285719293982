import { Flex } from '@dnb/eufemia';

import type { SimpleEvent } from '@/pages/events/EventModels';
import EventCard from '@/pages/explorer/events/EventCard';

interface EventListProps {
  events: SimpleEvent[];
}

export default function EventList({ events }: EventListProps) {
  return (
    <Flex.Stack>
      {events.map((event, index) => (
        <EventCard
          domainName={event.domain.name}
          key={index}
          name={event.name}
        />
      ))}
    </Flex.Stack>
  );
}
