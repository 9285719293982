/* eslint-disable max-lines */
import styled from '@emotion/styled';

import { colors, mq } from './Api.global.styles';

type ActionProps = {
  small: boolean;
  type: string | null;
};

export const IllustratedImage = styled.img`
  margin-left: 20%;
`;

export const Action = styled.div<ActionProps>`
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: ${(props: ActionProps) => (props?.small ? '0.5rem' : '1rem;')};
  padding: ${(props: ActionProps) => (props?.small ? '0' : '0.25rem')};
  color: var(--color-white);
  text-align: center;
  font-size: ${(props: ActionProps) =>
    props?.small ? '0.9rem' : 'var(--font-size-small)'};
  width: ${(props: ActionProps) => (props?.small ? '70px' : '100px')};
  background-color: ${(props: ActionProps) =>
    (props.type && colors[props.type]) || ''};
`;

export const Deprecated = styled.div`
  margin-left: var(--spacing-medium);
  background-color: var(--color-accent-yellow);
  padding: var(--spacing-xx-small) var(--spacing-x-small);
  border-radius: 4px;
  font-size: var(--font-size-basis);
`;

export const RowSection = styled.div`
  border-bottom: 1px solid var(--color-black-8);
  display: grid;
  grid-template-columns: 0.95fr 960px 1fr;

  .__tabs {
    margin-left: var(--spacing-large);
  }

  .__padding {
    display: none;
    background-color: ${(props) => props?.theme?.colors.background};

    ${mq['large']} {
      margin-left: 23rem;
      display: block;
    }
  }
`;

export const DocumentationButton = styled.div`
  margin-left: -3.2em;
  position: relative;

  &.active {
    bottom: -4px;
  }

  .settings {
    padding: 0.7rem;
    display: none;
    width: 16em;
    margin-left: -13em;
    top: -5em;
    background-color: white;

    box-shadow: var(--shadow-default);
    position: absolute;

    .item {
      border-top: 1px solid var(--color-black-3);
      border-bottom: 1px solid var(--color-black-3);
      padding-top: var(--spacing-small);
      padding-bottom: var(--spacing-small);
      .dnb-switch {
        width: 100%;

        .dnb-form-label {
          width: 80%;
        }
      }
    }
    &.active {
      display: block;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.2rem;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 2.6rem;
    border: 1px solid var(--color-sea-green);
    background-color: ${(props) => props?.theme?.colors.backgroundCard};
    box-shadow: var(--shadow-default);

    svg {
      color: var(--color-sea-green);
      width: 18px;
      margin-left: 3px;
    }

    &:hover {
      background-color: ${(props) => props?.theme?.colors.backgroundCard};
      border: 2px solid var(--color-sea-green);
      border-color: var(--color-sea-green);
      cursor: pointer;
      svg {
        color: var(--color-sea-green);
      }
    }
  }
`;

export const ErrorSpacing = styled.div`
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackToTop = styled.div`
  position: sticky;
  z-index: 10000;
  float: right;
  bottom: var(--spacing-x-large);
  justify-content: flex-end;
  background: transparent;
  margin-top: -5rem;
  padding-right: var(--spacing-xx-large);

  .scrollbtn {
    display: none;
    &.active {
      margin-top: -2.6em;
      display: flex;
    }
  }

  button:first-child {
    position: absolute;
    box-shadow: var(--shadow-default);
  }
`;

export const ChangeVersionWrapper = styled.div`
  background-color: ${(props) => props?.theme?.colors.backgroundCard};
  padding: var(--spacing-small);
  border: 1px solid var(--color-black-8);
  border-radius: 4px;
  margin-top: var(--spacing-medium);

  .dnb-badge {
    &.Deprecated {
      background-color: transparent;
      color: var(--color-signal-orange);
    }
    &.Removed {
      background-color: transparent;
      color: var(--color-fire-red);
    }
    &.Future {
      background-color: transparent;
      color: var(--color-success-green);
    }
  }
  h2,
  h4 {
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--spacing-small);
    padding-bottom: var(--spacing-small);
  }

  h2 {
    display: flex;
    span {
      margin-left: var(--spacing-small);
    }
  }
`;

export const SchemaWrapper = styled.div`
  ul.schema-item-section {
    margin: 0 0 var(--spacing-small) 0;
    padding: 0;
    border: 1px solid var(--color-black-8);
    background-color: ${(props) => props?.theme?.colors.backgroundCard};

    &.padding {
      margin: 10px;
    }

    li {
      list-style: none;

      button {
        padding-left: 0 !important;

        span {
          font-size: var(--font-size-small);
        }
      }

      button {
        padding-left: 0 !important;

        span {
          font-size: var(--font-size-small);
        }
      }

      &.active {
        padding-bottom: 10px;
        .dnb-button {
          margin-top: -1rem;
          .dnb-icon {
            transform: rotate(90deg);
          }
        }
        .schema-item {
          padding-bottom: 0;
        }
      }

      &.closed {
        padding-bottom: 0;
        .dnb-button {
          margin-top: calc(var(--spacing-small) * -1);
          .dnb-icon {
            transform: inherit;
          }
        }
        .schema-item {
          padding-bottom: var(--spacing-small);
        }
      }

      &:hover {
        background-color: inherit;
      }

      &:nth-child(2n) {
        background-color: ${(props) => props?.theme?.colors.backgroundCard2};
      }

      .schema-item {
        display: grid;
        grid-gap: var(--spacing-small);
        grid-template: inherit;
        justify-items: flex-start;
        padding: var(--spacing-small);

        ${mq['medium']} {
          grid-template: auto/200px 1fr;
        }

        em {
          white-space: nowrap;
        }

        strong {
          font-family: DnbMono, Courier New, Courier, monospace;
          font-weight: var(--font-weight-bold);
        }

        a.dnb-anchor {
          &.dnb-anchor--icon-right {
            display: flex;
            align-items: center;

            svg {
              width: 1rem;
            }
          }
        }

        .deprecated {
          background-color: var(--color-accent-yellow);
          padding: 0.125rem 0.25rem;
          display: inline-block;
          border-radius: 2px;
          color: black !important;
          margin-top: var(--spacing-x-small);
        }

        .id {
          p {
            color: ${(props) => props?.theme?.colors.menuSecondary};
            line-height: 140%;
            margin-top: 0;
            margin-bottom: 0;
            div {
              color: var(--color-fire-red);
            }
          }
        }
        .desc {
          overflow: auto;

          em {
            font-family: DnbMono, Courier New, Courier, monospace;
            font-style: normal;
            margin-right: 2px;
            background: ${(props) =>
              props?.theme?.colors.backgroundInteractionColor};
            color: ${(props) => props?.theme?.colors.interactionColor};
            padding: 0 0.25rem;
            border-radius: 2px;
          }

          .line {
            margin-bottom: var(--spacing-x-small);
          }
          .dnb-p {
            margin: 0;
          }
        }
      }
    }
  }
`;

export const Environment = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;

  ${mq['medium']} {
    flex-direction: row;
  }

  .dnb-dropdown {
    margin-bottom: var(--spacing-x-small);
    margin-right: 0;

    ${mq['small']} {
      margin-right: var(--spacing-medium);
    }

    ${mq['medium']} {
      margin-bottom: 0;
    }
  }
`;

export const ReleasesLabel = styled.div`
  margin-top: 6px;
  display: inline;
  border-radius: 4px;
`;
