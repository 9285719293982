import { Dropdown, Space, Tabs } from '@dnb/eufemia';
import type { OpenAPIV3 } from 'openapi-types';
import { useEffect, useState } from 'react';

import type { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';
import Example from '@/pages/api-documentation/sections/reference/components/Example';
import SchemaItem from '@/pages/api-documentation/sections/reference/components/SchemaItem';
import { transformSchema } from '@/pages/api-documentation/utils/transformations';

import { SchemaWrapper } from '@/pages/api-documentation/styles/Api.styles';

type SchemaProps = {
  data: Record<string, OpenAPIV3.MediaTypeObject>;
};

const Schema = ({ data }: SchemaProps) => {
  const [selected, setSelected] = useState(Object.keys(data)[0]);
  const [tab, selectTab] = useState('schema');
  const getData = (data: OpenAPIV3.MediaTypeObject) => {
    if (!data) {
      return null;
    }

    const section = data['schema'] as OpenAPIV3.ArraySchemaObject;
    if (Object.keys(data).includes('example')) {
      section['example'] = data['example'];
    }

    // IF WE HAVE allOf, anyOf or should combine objects.
    if (section && Object.keys(section).includes('allOf')) {
      return transformSchema(
        section as DereferencedSchemaObject,
      ) as OpenAPIV3.SchemaObject;
    }

    return section;
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setSelected(Object.keys(data)[0]);
    }
  }, [data]);

  const section = getData(data[selected]);

  return (
    <div>
      {Object.keys(data).length > 0 && (
        <Space bottom="small" top="small">
          <Dropdown
            data={Object.keys(data).map((item, index) => ({
              selected_key: `key_${index}`,
              content: item,
            }))}
            value="key_0"
          />
        </Space>
      )}

      {section && (
        <>
          <Tabs
            data={[
              { title: 'Schema', key: 'schema' },
              { title: 'Example', key: 'example' },
            ]}
            no_border
            on_change={({ key }) => selectTab(key)}
            selected_key={tab}
          />

          {(tab === 'schema' && (
            <SchemaWrapper>
              <ul className="schema-item-section">
                <SchemaItem item={section} />
              </ul>
            </SchemaWrapper>
          )) || <Example item={section} />}
        </>
      )}
    </div>
  );
};

export default Schema;
