import {
  Badge,
  Card,
  Dialog,
  Hr,
  Icon,
  Link,
  P,
  Space,
  Span,
  Tag,
  Tooltip,
  VisuallyHidden,
} from '@dnb/eufemia';
import { padlock as PadlockIcon, view as ViewIcon } from '@dnb/eufemia/icons';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import { Link as RouterLink } from 'react-router-dom';

import ApiFavouriteButton from '@/components/ApiFavouriteButton';
import { useUser } from '@/hooks/useUser';

import { QuickLook } from './QuickLook';

import style from './ApiCard.module.css';

const EXTERNAL_CLASSIFICATIONS = new Set<ApiDto['classification']>([
  'partner',
  'public',
]);

function visibilityReason(api: ApiDto) {
  if (api.organization && EXTERNAL_CLASSIFICATIONS.has(api.classification)) {
    return `${api.organization.name} organization`;
  }

  if (api.teams.length > 0) {
    if (api.organization?.name === 'DNB') {
      return null;
    }
    return `${api.teams[0].name} team`;
  }

  return null;
}

interface ApiCardProps {
  api: ApiDto;
  onApiFavouriteChange: (apiId: string, isFavorite: boolean) => void;
}

export default function ApiCard({
  api,
  onApiFavouriteChange,
}: ApiCardProps): JSX.Element {
  const visibleBecause = visibilityReason(api);
  const { user } = useUser();

  return (
    <Card bottom="small" className={style['ApiCard']} stretch>
      <Space className={style['TopSection']}>
        <P bottom="small">
          <Link
            element={RouterLink}
            to={`/documentation/${api.slug}/@default/@latest`}
          >
            {api.name}
          </Link>
          {api.stage != 'launched' && (
            <Badge
              content={api.stage === 'review' ? 'In review' : 'Draft'}
              left="small"
            />
          )}
          {visibleBecause && (
            <Tooltip
              hideDelay={0}
              noAnimation
              showDelay={100}
              size="large"
              targetElement={
                <Span left="small" right="x-small">
                  <Icon icon={PadlockIcon} size="medium" />
                </Span>
              }
            >
              <span>
                You can see this API because you are a member of the{' '}
                {visibleBecause}
              </span>
            </Tooltip>
          )}
        </P>

        {user && (
          <ApiFavouriteButton
            api={api}
            onChange={(apiId, isFavorite) =>
              onApiFavouriteChange(apiId, isFavorite)
            }
          />
        )}
      </Space>

      {api.currentVersion && (
        <>
          <VisuallyHidden>Current version</VisuallyHidden>
          <Span className={style['Version']}>{api.currentVersion}</Span>
        </>
      )}

      <P bottom="small" top="x-small">
        {api.description}
      </P>

      {api.tags.length > 0 && (
        <Tag.Group bottom="small" label="API tags">
          <Tag className={style['ClassificationTag']}>{api.classification}</Tag>
          {api.tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Tag.Group>
      )}

      <Hr className={style['Divider']} />

      <div className={style['ButtonsContainer']}>
        <Dialog
          maxWidth="60rem"
          triggerAttributes={{
            text: 'Quick view',
            variant: 'tertiary',
            icon: ViewIcon,
          }}
        >
          <QuickLook api={api} />
        </Dialog>
      </div>
    </Card>
  );
}
