import { Flex, Section } from '@dnb/eufemia';
import { cloud as CloudIcon, play as PlayIcon } from '@dnb/eufemia/icons';

import Page from '@/components/Page';
import ExplorerCard from '@/pages/explorer/ExplorerCard';

import style from '@/pages/explorer/ExplorerPage.module.css';

import explorerIllustrationUrl from '@/illustrations/Explorer.svg?url';

export default function ExplorerPage() {
  return (
    <div className="dark-mode">
      <Page
        description="Explore the APIs and events that DNB offer"
        illustration={
          <img
            alt="An astronaut looking at the Earth"
            className={style['Illustration']}
            src={explorerIllustrationUrl}
          />
        }
        spacing={false}
        title="Service Explorer"
      >
        <Section spacing="x-large" style_type="white">
          <Flex.Horizontal>
            <ExplorerCard
              description="Explore APIs"
              icon={CloudIcon}
              link="/explorer/apis"
              title="APIs"
            />
            <ExplorerCard
              description="Explore Events"
              icon={PlayIcon}
              link="/explorer/events"
              title="Events"
            />
          </Flex.Horizontal>
        </Section>
      </Page>
    </div>
  );
}
