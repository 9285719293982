// eslint-disable-next-line simple-import-sort/imports
import type { OpenAPIV3 } from 'openapi-types';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';

type ExampleItemProps = {
  item: OpenAPIV3.SchemaObject;
};

const Example = ({ item }: ExampleItemProps) => {
  function parseExample(item: OpenAPIV3.SchemaObject) {
    let data: any = null;

    if (item?.properties || item?.allOf) {
      data = {};
      if (item?.example) {
        data = item?.example;
      } else {
        if (item?.properties) {
          Object.keys(item.properties).forEach((subItem) => {
            data[subItem] =
              item?.properties &&
              item?.properties[subItem] &&
              parseExample(item?.properties[subItem] as OpenAPIV3.SchemaObject);
          });
        }
        if (item?.allOf) {
          for (let index = 0; index < item.allOf.length; index++) {
            const allOfItems =
              item?.allOf && (item.allOf[index] as OpenAPIV3.BaseSchemaObject);

            if (allOfItems?.properties) {
              Object.keys(
                allOfItems.properties as OpenAPIV3.SchemaObject,
              ).forEach((subItemKey) => {
                data[subItemKey] =
                  allOfItems?.properties &&
                  allOfItems?.properties[subItemKey] &&
                  parseExample(
                    allOfItems?.properties[
                      subItemKey
                    ] as OpenAPIV3.SchemaObject,
                  );
              });
            }
          }
        }

        if (
          item?.additionalProperties &&
          Object.keys(item?.additionalProperties).length > 0
        ) {
          const addProps = item.additionalProperties as OpenAPIV3.SchemaObject;

          if (addProps.properties) {
            Object.keys(addProps.properties as OpenAPIV3.SchemaObject).forEach(
              (subItem) => {
                data[subItem] =
                  addProps?.properties &&
                  addProps?.properties[subItem] &&
                  parseExample(
                    addProps?.properties[subItem] as OpenAPIV3.SchemaObject,
                  );
              },
            );
          } else if (
            Object.keys(addProps).includes('type') &&
            addProps.type === 'string'
          ) {
            return {
              property1: 'string',
              property2: 'string',
            };
          }
        }
      }
    } else if (item.type === 'array') {
      data = [];
      data.push(parseExample(item.items as OpenAPIV3.ArraySchemaObject));
    } else {
      if (item?.example) {
        data = item?.example;
      } else
        switch (item.type) {
          case 'integer': {
            data = 0;

            break;
          }
          case 'boolean': {
            data = true;

            break;
          }
          case 'object': {
            return {
              property1: 'string',
              property2: 'string',
            };
          }
          default: {
            data = item.type?.toString();
          }
        }
    }

    return data;
  }

  const exampleData = parseExample(item);
  const lang = 'json';

  let prismCode;

  if (exampleData) {
    try {
      prismCode = Prism.highlight(
        JSON.stringify(exampleData, null, '\t') || '',
        Prism.languages[lang],
        lang,
      );
    } catch {
      prismCode = Prism.highlight(
        JSON.stringify(exampleData) || '',
        Prism.languages['txt'],
        'txt',
      );
    }
  }

  return prismCode ? (
    <div className="code">
      <pre className={`dnb-pre prism-code language-${lang}`}>
        <div dangerouslySetInnerHTML={{ __html: prismCode }} />
      </pre>
    </div>
  ) : null;
};
export default Example;
