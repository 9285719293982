import { GlobalError, Space } from '@dnb/eufemia';
import type { OpenAPIV3 } from 'openapi-types';
import useSWR from 'swr';

import LoadingModal from '@/components/LoadingModal';
import { ApiError, request } from '@/request';

import type { APIType } from '../../api-documentation/constants/types';
import Reference from '../../api-documentation/sections/reference/ReferenceView';

interface ReferenceFromCommonRepoProps {
  apiLink: string;
}

export default function ReferenceFromCommonRepo({
  apiLink,
}: ReferenceFromCommonRepoProps): JSX.Element {
  const { data, isValidating } = useSWR<APIType | null>(
    `/common-repo/reference/${encodeURIComponent(apiLink)}`,
    async (url) => {
      try {
        const data = await request<APIType>(url, 'get');
        return data;
      } catch (error) {
        if (ApiError.isApiError(error) && error.status === 404) {
          return null;
        } else throw error;
      }
    },
  );

  if (isValidating) {
    return <LoadingModal />;
  }
  if (!data) {
    return (
      <Space bottom={10}>
        <GlobalError title="No reference present" />
      </Space>
    );
  }

  return (
    <Reference reference={data?.reference ?? ({} as OpenAPIV3.Document)} />
  );
}
