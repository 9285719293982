import { Button, H2, H3, Img, Li, Link, P, Section, Ul } from '@dnb/eufemia';
import { Link as RouterLink } from 'react-router-dom';

import Page from '@/components/Page';

import style from './GettingStarted.module.css';

import FireworksOnLaptopUrl from './FireworksOnLaptop.svg?url';
import FloatingBlobWithRopesUrl from './FloatingBlobWithRopes.svg?url';
import GirlCodingUrl from './GirlCoding.svg?url';
import ManWithComputerUrl from './ManWithComputer.svg?url';
import manWithPyramidsUrl from './ManWithPyramids.svg?url';
import MouseOverInterfaceUrl from './MouseOverInterface.svg?url';
import OverviewUrl from './Overview.svg?url';
import RocketLaunchUrl from './RocketLaunch.svg?url';
import SignUpScreenUrl from './SignUpScreen.svg?url';
import WomanPointingUrl from './WomanPointing.svg?url';

export default function GettingStarted(): JSX.Element {
  return (
    <Page
      description="Create awesome services by using DNB's data and services through APIs. Follow this guide to get up and running."
      illustration={
        <img
          alt="Man looking up a mountain"
          className={style['Illustration']}
          src={manWithPyramidsUrl}
        />
      }
      spacing={false}
      subtitle="Getting started guide"
      title="How to get going with our APIs"
    >
      <div className={style['GettingStartedContent']}>
        <H2 bottom="0" top="x-large">
          Follow these steps to get started
        </H2>
        <Section style_type="white">
          <Img alt="Girl signing up" src={SignUpScreenUrl} width={340} />
          <div>
            <H3>1 Sign up</H3>
            <P top="small">
              Sign up using your email address. Creating an account is free, no
              strings attached. You will need to verify your account with the
              verification code sent to your email. And if you are associated
              with a company, remember to use your work email address.
            </P>
            <P top="small">
              We highly recommend enabling multi-factor authentication (MFA)
              right away to add additional security to your account.
            </P>
          </div>
        </Section>
        <Section>
          <Img alt="Girl coding" src={GirlCodingUrl} width={280} />
          <div>
            <H3>2 Create an app </H3>
            <P top="small">
              Create an app and name it according to your service that will be
              using the API. You can also add a description that provides
              further details on what the app is for.
            </P>
          </div>
        </Section>
        <Section style_type="white">
          <Img alt="Man With Computer" src={ManWithComputerUrl} width={300} />
          <div>
            <H3>3 Get your credentials</H3>
            <P top="small">
              We operate with 2 different types of credentials: API keys and
              certificates. What type of authentication/authorisation scheme you
              should use depends on what API you&apos;re consuming.
            </P>
            <Ul top="small">
              <Li>
                <strong>API keys:</strong> Generate API keys from the app page.
                Copy the API key and store it somewhere safe. Never expose your
                API key publicly, as it can compromise security. Please note
                that the keys are different for test mode and live mode.
              </Li>
              <Li>
                <strong>Certificates:</strong> Currently only used for the
                sandbox environment of our PSD2 APIs, namely the Account
                Information Service API and Payment Initiation Service API.
                Generate a PSD2 certificate from the Certificates tab in My
                Profile. Provide your information and select PSD2 roles. A
                client certificate (.pem) and private key (.key) will be
                downloaded automatically. Remember to store them in a safe
                place, as this is the only time you will be able to download
                them.
              </Li>
            </Ul>
          </div>
        </Section>
        <Section>
          <Img
            alt="Mouse over interface"
            src={MouseOverInterfaceUrl}
            width={270}
          />
          <div>
            <H3>4 Add an API </H3>
            <P top="small">
              To get access to an API, you need to attach the API to your app.
              You will be prompted to select which APIs you want to attach when
              you are creating your app. Once the app is created, keys are
              generated and your test keys will be automatically authorised to
              fetch data from sandbox environment.
            </P>
          </div>
        </Section>
        <Section style_type="white">
          <Img
            alt="Fireworks on laptop"
            src={FireworksOnLaptopUrl}
            width={270}
          />
          <div>
            <H3>5 Quickstart using Postman (optional)</H3>
            <P top="small">
              To test our APIs, we recommend using the free tool{' '}
              <Link href="http://www.getpostman.com/" target="_blank">
                Postman
              </Link>
              . Some of our APIs also come with Postman collections that you can
              import into your Postman workspace, or with example code, which
              you can find on Github. For further details on quick start
              resources relevant to the API you&apos;re interested in, check out
              the API&apos;s documentation page.
            </P>
          </div>
        </Section>
        <Section>
          <Img
            alt="People holding a floating blob with ropes"
            src={FloatingBlobWithRopesUrl}
            width={300}
          />
          <div>
            <H3>6 Make an API request</H3>
            <P top="small">
              Everything should now be ready to send your first request. Whether
              you&apos;re using Postman, your terminal, or another setup, you
              should now get responses in the 200 range from the test mode
              server.
            </P>
          </div>
        </Section>
        <Section style_type="white">
          <Img alt="Rocket launch" src={RocketLaunchUrl} width={270} />
          <div>
            <H3>7 Go live</H3>
            <P top="small">
              The next step is to apply for access to live mode. Before you can
              request access to live data, you have to enable MFA for your
              account from your account page. You can apply for access by going
              to the app page of the app you want access for. Scroll down to the
              bottom of page, find the API you want live access to and click on
              the <b>Request live access</b>-button. Provide all necessary
              information, submit your request and wait for approval. Once your
              access request has been approved, you&apos;re ready to make
              requests to the live API using your live mode credentials.
            </P>
          </div>
        </Section>
        <Section>
          <Img alt="Woman pointing" src={WomanPointingUrl} width={180} />
          <div>
            <P top="small">
              Please note that this getting started guide only contains generic
              instructions on how to get started. For more detailed information
              about a particular API, please visit the API&apos;s documentation.
            </P>
            <Button
              element={RouterLink}
              size="large"
              to="/explorer/apis"
              top="small"
            >
              Find your API
            </Button>
          </div>
        </Section>
        <Section style_type="white">
          <Img
            alt="A person standing on a ladder and looking out"
            src={OverviewUrl}
            width={350}
          />
          <div>
            <H3>Still lost?</H3>
            <P top="small">
              Check out our{' '}
              <Link element={RouterLink} to="/help-center">
                {' '}
                Help Center
              </Link>
              .
            </P>
          </div>
        </Section>
      </div>
    </Page>
  );
}
