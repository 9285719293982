import { Button, FormRow, FormStatus } from '@dnb/eufemia';

interface RequestModalFormNavigationProps {
  enableServiceNowRequestForm: boolean;
  onPresubmit: () => void;
}

export default function RequestModalFormNavigation({
  enableServiceNowRequestForm,
  onPresubmit,
}: RequestModalFormNavigationProps) {
  return (
    <FormRow top="large">
      {!enableServiceNowRequestForm && (
        <Button type="submit">Submit request</Button>
      )}

      {enableServiceNowRequestForm && (
        <>
          <FormStatus
            bottom="small"
            state="info"
            text="In the next step you'll be authenticated via a popup with a third-party service before accessing the final form."
          />
          <Button on_click={() => onPresubmit()}>Next</Button>
        </>
      )}
    </FormRow>
  );
}
