import type { ServiceNowInfoInputDto } from '@portals/shared/portal/ApiDto';
import type {
  ApiKeyDto,
  CreateApiKeyRequestDto,
} from '@portals/shared/portal/ApiKeyDto';
import type {
  AppApiEntitlementInputRequest,
  AppApiEntitlementRequest,
  EntitlementRequestDto,
} from '@portals/shared/portal/AppApiEntitlementDto';
import type {
  AppDto,
  CreateAppRequestDto,
  CreateAppResponseDto,
  UpdateAppRequestDto,
  UpdateAppResponseDto,
} from '@portals/shared/portal/AppDto';
import type { CreateOAuthClientRequest } from '@portals/shared/portal/OAuthClientDto';
import type { CreateApiAccessTicketVariablesInputRequest } from '@portals/shared/portal/SupportTicket';

import { request } from '@/request';

export function createApp(
  app: CreateAppRequestDto,
): Promise<CreateAppResponseDto> {
  return request('/app', 'post', app);
}

export function attachApi(
  appId: string,
  appApiEntitlementRequest: AppApiEntitlementInputRequest,
): Promise<void> {
  return request(
    `/apps/${appId}/apis/test-mode`,
    'post',
    appApiEntitlementRequest,
  );
}

export function editApp(
  appId: string,
  app: UpdateAppRequestDto,
): Promise<UpdateAppResponseDto> {
  return request(`/app/${appId}`, 'patch', app);
}

export function deleteApp(appId: string): Promise<AppDto> {
  return request(`/apps/${appId}`, 'delete');
}

export function createApiKey(
  appId: string,
  data: CreateApiKeyRequestDto,
): Promise<ApiKeyDto> {
  return request(`/apps/${appId}/api-keys/`, 'post', data);
}

export function deleteApiKey(appId: string, key: string): Promise<ApiKeyDto> {
  return request(`/apps/${appId}/api-keys/${key}`, 'delete');
}

export function detachApi(appId: string, apiId: string): Promise<void> {
  return request(`/apps/${appId}/apis/${apiId}`, 'delete');
}

export async function requestLiveMode(
  appId: string,
  payload: AppApiEntitlementRequest,
): Promise<EntitlementRequestDto> {
  return request(`/apps/${appId}/apis/live-mode`, 'post', payload);
}

export async function requestLiveModeV2(
  appId: string,
  payload: CreateApiAccessTicketVariablesInputRequest,
): Promise<void> {
  return request(`/apps/${appId}/service-now`, 'post', payload);
}

export async function editTestModeScopes(
  appId: string,
  apiId: string,
  selectedScopeIds: string[],
): Promise<void> {
  return request(`/apps/${appId}/apis/${apiId}/test-mode`, 'put', {
    selectedScopeIds,
  });
}

export async function cancelPendingRequest(
  appId: string,
  apiId: string,
  entitlementRequestId: string,
): Promise<void> {
  return request(
    `/apps/${appId}/apis/${apiId}/entitlement-requests/${entitlementRequestId}`,
    'delete',
  );
}

export async function acknowledgeDenial(
  appId: string,
  apiId: string,
  entitlementRequestId: string,
): Promise<void> {
  return request(
    `/apps/${appId}/apis/${apiId}/entitlement-requests/${entitlementRequestId}/acknowledge-denial`,
    'patch',
  );
}

export function createClient(
  appId: string,
  data: CreateOAuthClientRequest,
): Promise<ApiKeyDto> {
  return request(`/apps/${appId}/clients`, 'post', data);
}

export function deleteClient(appId: string, clientId: string): Promise<void> {
  return request(`/apps/${appId}/clients/${clientId}`, 'delete');
}

export function deleteClientFromPortal(
  appId: string,
  clientId: string,
): Promise<void> {
  return request(`/apps/${appId}/clients/softdelete/${clientId}`, 'delete');
}

export function publishApiSlackNotification(
  apiId: string,
  appId: string,
): Promise<ApiKeyDto> {
  return request(`/apis/${apiId}/apps/${appId}/publish-api-request`, 'post');
}

export function makeApiAvailableForOauth(
  apiId: string,
  snInfo: ServiceNowInfoInputDto,
): Promise<void> {
  return request(`/oauth-apis/${apiId}`, 'patch', snInfo);
}
