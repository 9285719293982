import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Page from '@/components/Page';
import type { SimpleEvent } from '@/pages/events/EventModels';

export default function EventPage() {
  const { slug } = useParams();
  const { data: event, isValidating } = useSWR<SimpleEvent>(
    slug && `/v2/events/${slug}`,
  );

  return <Page skeleton={isValidating} spacing={false} title={event?.name} />;
}
