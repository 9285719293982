import { Button, Div, H2, P, Space } from '@dnb/eufemia';

import Card from '@/components/Card';
import Container from '@/components/Container';
import ModalPage from '@/components/ModalPage';

import style from './RequestSentSuccessModal.module.css';

import clapIllustrationUrl from '@/illustrations/clap.svg?url';

type RequestSentSuccessModalProps = {
  onClose: () => void;
  title?: string;
  text?: string;
};

export default function RequestSentSuccessModal({
  onClose,
  title,
  text,
}: RequestSentSuccessModalProps): JSX.Element {
  return (
    <ModalPage actualModal hideCloseButton>
      <Container centered size="small">
        <Card className={style['Card']}>
          <H2 bottom={0} className={style['success-text']}>
            Yay!
          </H2>
          <H2 bottom={0}>{title ?? 'Access request sent!'}</H2>

          <Space bottom="medium">
            <img src={clapIllustrationUrl} />
          </Space>
          <P bottom="medium" size="small">
            {text ??
              'Your access request has been sent! Sit back and relax while it is being handled.'}
          </P>
          <Div>
            <Button
              on_click={onClose}
              right="medium"
              size="large"
              text="Go to application"
              variant="secondary"
            />
          </Div>
        </Card>
      </Container>
    </ModalPage>
  );
}
