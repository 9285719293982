import { Flex, FormStatus, InfoCard, P, ProgressIndicator } from '@dnb/eufemia';
import { useState } from 'react';
import useSWR from 'swr';

import Page from '@/components/Page';
import type { SimpleEvent } from '@/pages/events/EventModels';
import EventFilter from '@/pages/explorer/events/EventFilter';
import EventList from '@/pages/explorer/events/EventList';

import style from '@/pages/explorer/events/EventExplorerPage.module.css';

import explorerIllustrationUrl from '@/illustrations/Explorer.svg?url';

export default function EventExplorerPage() {
  const {
    data: events,
    isValidating: isLoading,
    error: error,
  } = useSWR<SimpleEvent[], Error>('/v2/events', { onError: () => {} });

  const [filteredEvents, setFilteredEvents] = useState<SimpleEvent[]>([]);

  return (
    <div className="dark-mode">
      <Page
        description="Discover events that enable you to create great user experiences"
        illustration={
          <img
            alt="An astronaut looking at the Earth"
            className={style['Illustration']}
            src={explorerIllustrationUrl}
          />
        }
        spacing={false}
        subtitle="Discover events"
        title="Events Explorer"
      >
        <Flex.Stack bottom="x-large" top="x-large">
          <EventFilter events={events || []} onChange={setFilteredEvents} />
          {error ? (
            <FormStatus stretch text="Failed to fetch events." />
          ) : isLoading ? (
            <ProgressIndicator label="Fetching events..." />
          ) : filteredEvents.length === 0 ? (
            <InfoCard text="No events match the selected filters." />
          ) : (
            <>
              <P bottom="medium">
                Showing <b>{filteredEvents.length}</b> of{' '}
                <b>{events?.length || 0}</b> events
              </P>
              <EventList events={filteredEvents} />
            </>
          )}
        </Flex.Stack>
      </Page>
    </div>
  );
}
