import { Button, Div } from '@dnb/eufemia';
import { edit as EditIcon, play as PlayIcon } from '@dnb/eufemia/icons';
import type { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import { Link as RouterLink } from 'react-router-dom';

type BottomButtonsProps = {
  accessType: number;
  entitlement: CurrentApiAndScopeEntitlementsDto;
  hasPendingRequest: boolean;
  hasDeniedRequest: boolean;
  setShowRequestLiveModeModal: (arg0: boolean) => void;
  hasLiveAccess: boolean;
  isScopeInSync: boolean;
  setShowEditScopesModal: (arg0: boolean) => void;
};

const BottomButtons = ({
  accessType,
  entitlement,
  hasPendingRequest,
  hasDeniedRequest,
  setShowRequestLiveModeModal,
  hasLiveAccess,
  isScopeInSync,
  setShowEditScopesModal,
}: BottomButtonsProps): JSX.Element => {
  const hasPendingOrDeniedRequest = hasPendingRequest || hasDeniedRequest;
  return (
    <Div>
      {accessType < 2 &&
        entitlement.api.availableInLiveMode &&
        !hasPendingOrDeniedRequest && (
          <Button
            icon={PlayIcon}
            icon_position="left"
            on_click={() => setShowRequestLiveModeModal(true)}
            right="small"
            variant="secondary"
          >
            {accessType === 0 ? 'Request access' : 'Request live access'}
          </Button>
        )}
      {(entitlement?.api.approvalStage === 'test' &&
        hasLiveAccess &&
        entitlement.api.availableInLiveMode &&
        !hasPendingOrDeniedRequest && (
          <Button
            icon={PlayIcon}
            icon_position="left"
            on_click={() => setShowRequestLiveModeModal(true)}
            right="small"
            variant="secondary"
          >
            Edit scopes
          </Button>
        )) || (
        <>
          {hasLiveAccess &&
            entitlement.api.availableInLiveMode &&
            !hasPendingOrDeniedRequest &&
            !isScopeInSync && (
              <Button
                icon={PlayIcon}
                icon_position="left"
                on_click={() => setShowRequestLiveModeModal(true)}
                right="small"
                variant="secondary"
              >
                Sync access
              </Button>
            )}
          {entitlement?.api.approvalStage !== 'test' &&
            entitlement?.scopeEntitlements?.length > 0 && (
              <Button
                icon={EditIcon}
                icon_position="left"
                on_click={() => setShowEditScopesModal(true)}
                right="small"
                variant="secondary"
              >
                Edit scopes
              </Button>
            )}
        </>
      )}

      <Button
        element={RouterLink}
        icon="chevron-right"
        icon_position="right"
        target="_blank"
        to={`/documentation/${entitlement.api.slug}/@default/@latest/guide`}
        variant="secondary"
      >
        Documentation
      </Button>
    </Div>
  );
};
export default BottomButtons;
