import { Button, FormRow, FormSet, FormStatus } from '@dnb/eufemia';
import type { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { z } from 'zod';

import { editTestModeScopes } from '@/api/app';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';

import RequestScopes from './components/RequestScopes';
import RequestSentSuccessModal from './RequestSentSuccessModal';

const editScpoeSchema = z.object({
  selectedScopeIds: z
    .array(z.string())
    .min(1, 'Please select at least 1 scope.'),
});

type EditScopesProps = {
  entitlement: CurrentApiAndScopeEntitlementsDto;
  onClose: () => void;
};

export default function EditScopes({ entitlement, onClose }: EditScopesProps) {
  const { id: appId } = useParams();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    controller: { setValue, errors },
    controller,
    handleSubmit,
    submitting,
  } = useEufemiaForm(editScpoeSchema, {});

  const existingScopeIds = entitlement?.scopeEntitlements
    .filter((scope) => !scope.liveMode)
    .map(({ scopeId }) => scopeId);

  const scopes = entitlement.api.scopes?.map((scope) => {
    if (existingScopeIds?.includes(scope.id)) {
      return {
        id: scope.id,
        name: scope.name,
        description: scope.description,
        selected: true,
      };
    }
    return {
      id: scope.id,
      name: scope.name,
      description: scope.description,
      selected: false,
    };
  });

  useEffect(() => {
    if (scopes) {
      setValue(
        'selectedScopeIds',
        scopes?.filter(({ selected }) => selected).map(({ id }) => id),
      );
    }
  }, []);

  const onSubmit = handleSubmit(async ({ selectedScopeIds }) => {
    if (appId) {
      try {
        await editTestModeScopes(appId, entitlement.api.id, selectedScopeIds);
        mutate(`/apps/${appId}`);
        setSuccess(true);
      } catch (error) {
        if (ApiError.isApiError(error)) {
          setErrorMessage(error.body.message);
        } else {
          throw error;
        }
      }
    }
  });

  const onScopeChange = (scopeId: string, checked: boolean) => {
    if (checked) {
      setValue('selectedScopeIds', [
        ...(controller.values.selectedScopeIds || []),
        scopeId,
      ]);
      controller.triggerValidation('selectedScopeIds');
    } else {
      setValue(
        'selectedScopeIds',
        controller.values?.selectedScopeIds?.filter((id) => scopeId !== id),
      );
    }
  };

  if (success) {
    return (
      <RequestSentSuccessModal
        onClose={onClose}
        text="The scopes for your app is now updated! Go back to the application to check it out!"
        title="Your scopes are updated!"
      />
    );
  }

  return (
    <ModalPage
      actualModal
      onClose={onClose}
      subtitle="You are now in the process of editing your scopes"
      title="Edit scopes"
    >
      {submitting && <LoadingModal />}
      <FormSet on_submit={onSubmit}>
        <RequestScopes
          apiDescription={entitlement.api.description}
          apiName={entitlement.api.name}
          onChange={onScopeChange}
          scopes={scopes ?? []}
        />
        {errors['selectedScopeIds'] && (
          <FormRow top="x-small">
            <FormStatus text={errors['selectedScopeIds']} />
          </FormRow>
        )}
        {errorMessage && (
          <FormRow top="x-small">
            <FormStatus state="error" text={errorMessage} />
          </FormRow>
        )}

        <FormRow top="large">
          <Button on_click={onClose} right="small" variant="secondary">
            Cancel
          </Button>
          <Button type="submit">Send</Button>
        </FormRow>
      </FormSet>
    </ModalPage>
  );
}
