import Logo from './logo.svg';

export default function DeveloperLogo(): JSX.Element {
  return (
    <span
      className="dnb-logo dnb-logo--inherit-size"
      style={{ height: '4.5rem' }}
    >
      <Logo />
    </span>
  );
}
