import { Anchor, Button, Card, Dialog, Flex, Hr, Tag } from '@dnb/eufemia';
import { star as StarIcon, view as ViewIcon } from '@dnb/eufemia/icons';
import { Link } from 'react-router-dom';

import style from './index.module.css';

interface EventCardProps {
  name: string;
  domainName: string;
}

export default function EventCard({ name, domainName }: EventCardProps) {
  const slug = name.replaceAll('.', '-');

  return (
    <Card className={style['Card']} stack>
      <Flex.Horizontal justify="space-between">
        <Anchor element={Link} target="_blank" to={`/events/${slug}`}>
          {name}
        </Anchor>
        <Button icon={StarIcon} />
      </Flex.Horizontal>
      <Flex.Item>
        <Tag text={domainName} />
      </Flex.Item>
      <Flex.Item>
        <Hr className={style['Divider']} />
        <Dialog
          maxWidth="60rem"
          triggerAttributes={{
            text: 'Quick view',
            variant: 'tertiary',
            icon: ViewIcon,
          }}
        />
      </Flex.Item>
    </Card>
  );
}
