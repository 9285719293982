import { Button, Link, P, Space } from '@dnb/eufemia';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { OpenAPIV3 } from 'openapi-types';
import { useState } from 'react';

import Markdown from '@/components/Markdown';

type SchemaItemProps = {
  item: OpenAPIV3.SchemaObject;
  name?: string;
  required?: boolean;
};

const SchemaItem = ({ item, name = '', required = false }: SchemaItemProps) => {
  const [opened, setOpened] = useState(false);
  const range = (min?: number, max?: number): string | null => {
    if (min != null && max != null) {
      return `[${min}, ${max}]`;
    }

    if (max != null) {
      return `≤ ${max}`;
    }

    if (min != null) {
      return `≥ ${min}`;
    }

    return null;
  };

  if (!item) return null;

  return (
    <li className={`${(opened && 'active') || 'closed'}`}>
      <div className="schema-item">
        <div className="id">
          {name && <strong>{name}</strong>}
          {item.oneOf && <P>object</P>}
          <P>
            {required && <div>required</div>}
            {item?.type} {item?.nullable && '| null'}
            {item?.format && `<${item.format}>`}
            {item?.deprecated && (
              <div>
                <div className="deprecated">Deprecated</div>
              </div>
            )}
          </P>
        </div>
        <div className="desc">
          {item.description && <Markdown>{item.description}</Markdown>}
          {item.pattern && (
            <div>
              <strong>Pattern:</strong> <em>{item.pattern}</em>
            </div>
          )}
          {item.enum && (
            <div className="line">
              <strong>Enum:</strong>{' '}
              {item.enum.map((enu: string, i: number) => (
                <em className="label" key={`code-section-${enu}-${i}`}>
                  &#34;{enu}&#34;
                </em>
              ))}
            </div>
          )}

          {item?.anyOf && (
            <>
              <strong>All of</strong> {item.anyOf}
            </>
          )}
          {item?.oneOf && (
            <>
              One of: <strong>object</strong>
            </>
          )}
          {item?.maximum && (
            <>
              <strong>Range:</strong> {range(item.minimum, item.maximum)}
            </>
          )}
          {item?.maxItems && (
            <>
              <strong>Length:</strong> {range(item.minItems, item.maxItems)}
            </>
          )}
          {item?.maxLength && (
            <>
              <strong>Length:</strong> {range(item.minLength, item.maxLength)}
            </>
          )}

          {Object.keys(item).includes('default') && (
            <div className="line">
              <strong>Default:</strong>
              <em className="label">
                {(typeof item.default === 'boolean' && (
                  <>{item.default.toString()}</>
                )) || <>&#34;{item.default}&#34;</>}
              </em>
            </div>
          )}

          {item?.externalDocs && (
            <div className="line">
              <Space top="x-small" />
              {(item?.externalDocs?.description && (
                <Link href={item.externalDocs.url} target="_blank">
                  <Markdown>{item?.externalDocs?.description}</Markdown>
                </Link>
              )) || (
                <Link href={item.externalDocs.url} target="_blank">
                  {item.externalDocs.url}
                </Link>
              )}
            </div>
          )}
        </div>
        {(item?.properties ||
          Object.keys(item).includes('items') ||
          item?.oneOf ||
          item?.allOf ||
          item?.additionalProperties) && (
          <Button
            icon="chevron_right"
            on_click={() => setOpened(!opened)}
            variant="tertiary"
          >
            {(item?.properties && 'Properties') || 'Items'}
          </Button>
        )}
      </div>

      {opened && (
        <>
          {item.properties && (
            <ul className="schema-item-section padding">
              {Object.keys(item?.properties).map((property) => (
                <SchemaItem
                  item={
                    (item?.properties &&
                      (item?.properties[property] as OpenAPIV3.SchemaObject)) ||
                    {}
                  }
                  key={`schema-item-${property}`}
                  name={property}
                  required={item?.required?.includes(property)}
                />
              ))}
            </ul>
          )}

          {item?.additionalProperties && (
            <ul className="schema-item-section padding">
              <SchemaItem
                item={item?.additionalProperties as OpenAPIV3.SchemaObject}
                name="<name>"
              />
            </ul>
          )}

          {item?.oneOf && (
            <ul className="schema-item-section padding">
              {item.oneOf.map((oneItem) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {!hasOwnProperty(oneItem, '$ref') && oneItem.properties && (
                    <>
                      {Object.keys(oneItem.properties).map((property) => (
                        <SchemaItem
                          item={
                            (oneItem?.properties &&
                              (oneItem?.properties[
                                property
                              ] as OpenAPIV3.SchemaObject)) ||
                            {}
                          }
                          key={`schema-item-${property}`}
                          name={property}
                          required={oneItem?.required?.includes(property)}
                        />
                      ))}
                    </>
                  )}
                </>
              ))}
            </ul>
          )}
          {item?.allOf && (
            <ul className="schema-item-section padding">
              {item.allOf.map((oneItem) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {!hasOwnProperty(oneItem, '$ref') && oneItem.properties && (
                    <>
                      {Object.keys(oneItem.properties).map((property) => (
                        <SchemaItem
                          item={
                            (oneItem?.properties &&
                              (oneItem?.properties[
                                property
                              ] as OpenAPIV3.SchemaObject)) ||
                            {}
                          }
                          key={`schema-item-${property}`}
                          name={property}
                          required={oneItem?.required?.includes(property)}
                        />
                      ))}
                    </>
                  )}
                </>
              ))}
            </ul>
          )}
          {item.type === 'array' && (
            <ul className="schema-item-section padding">
              <SchemaItem item={item?.items as OpenAPIV3.SchemaObject} />
            </ul>
          )}
        </>
      )}
    </li>
  );
};

export default SchemaItem;
