import type { OpenAPIV3 } from 'openapi-types';

import SchemaItem from '@/pages/api-documentation/sections/reference/components/SchemaItem';

import { H4S } from '@/pages/api-documentation/styles/Api.Reference.styles';
import { SchemaWrapper } from '@/pages/api-documentation/styles/Api.styles';

type ParametersProps = {
  data: OpenAPIV3.ParameterObject[];
};

const Parameters = ({ data }: ParametersProps) => {
  const hasHead = data.filter((item) => item.in === 'header');
  const hasPath = data.filter((item) => item.in === 'path');
  const hasQuery = data.filter((item) => item.in === 'query');
  const hasCookie = data.filter((item) => item.in === 'cookie');

  return (
    <>
      {hasHead?.length !== 0 && (
        <>
          <H4S>Header parameters</H4S>
          <SchemaWrapper>
            <ul className="schema-item-section">
              {hasHead.map((item, index) => (
                <SchemaItem
                  item={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as OpenAPIV3.SchemaObject
                  }
                  key={`path-param-${item?.name}-${index}`}
                  name={item?.name}
                  required={item?.required}
                />
              ))}
            </ul>
          </SchemaWrapper>
        </>
      )}
      {hasPath?.length !== 0 && (
        <>
          <H4S>Path parameters</H4S>
          <SchemaWrapper>
            <ul className="schema-item-section">
              {hasPath.map((item) => (
                <SchemaItem
                  item={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as OpenAPIV3.SchemaObject
                  }
                  key={`path-param-${item?.name}`}
                  name={item?.name}
                  required={item?.required}
                />
              ))}
            </ul>
          </SchemaWrapper>
        </>
      )}
      {hasQuery?.length !== 0 && (
        <>
          <H4S>Query parameters</H4S>
          <SchemaWrapper>
            <ul className="schema-item-section">
              {hasQuery.map((item) => (
                <SchemaItem
                  item={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as OpenAPIV3.SchemaObject
                  }
                  key={`query-param-${item?.name}`}
                  name={item?.name}
                  required={item?.required}
                />
              ))}
            </ul>
          </SchemaWrapper>
        </>
      )}
      {hasCookie?.length !== 0 && (
        <>
          <H4S>Cookie parameters</H4S>
          <SchemaWrapper>
            <ul className="schema-item-section">
              {hasCookie.map((item) => (
                <SchemaItem
                  item={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as OpenAPIV3.SchemaObject
                  }
                  key={`query-param-${item?.name}`}
                  name={item?.name}
                  required={item?.required}
                />
              ))}
            </ul>
          </SchemaWrapper>
        </>
      )}
    </>
  );
};

export default Parameters;
