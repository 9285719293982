import type {
  DereferencedParameterObject,
  DereferencedSchemaObject,
} from '@/pages/api-documentation/constants/types';

type SchemaKeys = keyof DereferencedSchemaObject;
type ReferenceMap = Map<
  DereferencedSchemaObject,
  Map<DereferencedSchemaObject, DereferencedSchemaObject>
>;

/**
 * Euclidean GCD
 */

function gcd(number1: number, number2: number): number {
  if (number1 < number2) {
    const temporary = number1;
    number1 = number2;
    number2 = temporary;
  }

  if (number1 % number2 == 0) {
    return number2;
  } else {
    number1 = number1 % number2;
  }

  return gcd(number2, number1);
}

/**
 * Merging strategies for schema definitions
 */

const strategies: Record<
  string,
  (
    key: SchemaKeys,
    first: DereferencedSchemaObject,
    second: DereferencedSchemaObject,
    references: ReferenceMap,
  ) => DereferencedSchemaObject[typeof key]
> = {
  /**
   * Fall through to lower schema
   */

  fallthrough(key, first, second) {
    return first[key] ?? second[key];
  },

  /**
   * Pick maximum
   */

  maximum(key, first, second) {
    if (second[key] === undefined) {
      return first[key];
    }

    if (first[key] === undefined) {
      return second[key];
    }

    return first[key] > second[key] ? first[key] : second[key];
  },

  /**
   * Pick minimum
   */

  minimum(key, first, second) {
    if (second[key] === undefined) {
      return first[key];
    }

    if (first[key] === undefined) {
      return second[key];
    }

    return first[key] < second[key] ? first[key] : second[key];
  },

  /**
   * Pick least common multiple
   */
  lcm(key, first, second) {
    if (typeof first[key] !== 'number') {
      return second[key];
    }

    if (typeof second[key] !== 'number') {
      return first[key];
    }

    return (first[key] * second[key]) / gcd(first[key], second[key]);
  },

  /**
   * Pick higher and print warning
   */

  incompatible(key, first, second) {
    if (
      first[key] !== second[key] &&
      first[key] !== undefined &&
      second[key] !== undefined
    ) {
      // eslint-disable-next-line no-console
      console.warn(`Merge mismatch: ${key}`);
    }

    return first[key] ?? second[key];
  },

  /**
   * Merge and dedupe
   */

  unique(key, first, second) {
    const merged = new Set();
    const primary = first[key];
    const secondary = second[key];

    if (primary == null && secondary == null) {
      return;
    }

    if (Array.isArray(primary)) {
      primary.forEach((value) => merged.add(value));
    }

    if (Array.isArray(secondary)) {
      secondary.forEach((value) => merged.add(value));
    }

    // eslint-disable-next-line unicorn/prefer-spread
    return Array.from(merged);
  },
};

const keys: Record<keyof typeof strategies, SchemaKeys[]> = {
  fallthrough: [
    'title',
    'default',
    'deprecated',
    'description',
    'nullable',
    'example',
    'exclusiveMinimum',
    'exclusiveMaximum',
    'uniqueItems',
  ],
  unique: ['required'],
  maximum: ['minimum', 'minLength', 'minItems', 'minProperties'],
  minimum: ['maximum', 'maxLength', 'maxItems', 'maxProperties'],
  lcm: ['multipleOf'],
  incompatible: [
    'type',
    'format',
    'additionalProperties',
    // @ts-ignore because the types are dumb
    'items',
    'enum',
    'pattern',

    // Not handled yet
    'readOnly',
    'writeOnly',
    'allOf',
    'oneOf',
    'anyOf',
    'not',
    'discriminator',
    'xml',
  ],
};

/**
 * Merge schema properties
 */

function mergeProperties(
  schema: DereferencedSchemaObject,
  other: DereferencedSchemaObject,
  references: ReferenceMap,
) {
  if (schema.properties === undefined && other.properties === undefined) {
    return;
  }

  const props = Object.assign({}, schema.properties);

  if (other.properties === undefined) {
    return props;
  }

  Object.entries(other.properties).forEach(([key, prop]) => {
    if (prop === undefined) {
      return;
    }

    if (props[key] === undefined) {
      props[key] = prop;
      return;
    }

    props[key] = mergeSchemas(props[key], prop, references);
  });

  return props;
}

/**
 * Merge schema definitions
 */

export function mergeSchemas(
  schema: DereferencedSchemaObject,
  other: DereferencedSchemaObject,
  references: ReferenceMap = new Map(),
): DereferencedSchemaObject {
  const merged: DereferencedSchemaObject = {};
  const seen = references.get(schema);

  if (seen) {
    const reference = seen.get(other);

    if (reference) {
      return reference;
    }

    seen.set(other, merged);
  } else {
    const map = new Map();
    map.set(other, merged);
    references.set(schema, map);
  }

  Object.entries(keys).forEach(([strategy, keys]) => {
    keys.forEach((key) => {
      const result = strategies[strategy](key, schema, other, references);
      if (result !== undefined) {
        merged[key] = result;
      }
    });
  });

  const props = mergeProperties(schema, other, references);

  if (props) {
    merged.properties = props;
  }

  return merged;
}

export function mergeParameters(
  lower?: DereferencedParameterObject[],
  higher?: DereferencedParameterObject[],
): DereferencedParameterObject[] {
  const parametersByKey: Record<string, DereferencedParameterObject> = {};

  const parameterKey = (parameter: DereferencedParameterObject) =>
    [parameter.in, parameter.name].join(':');

  higher?.forEach(
    (parameter) => (parametersByKey[parameterKey(parameter)] = parameter),
  );

  // Lower parameters should overwrite higher parameters with the same name
  lower?.forEach(
    (parameter) => (parametersByKey[parameterKey(parameter)] = parameter),
  );

  return Object.values(parametersByKey);
}

/**
 * Transform schema into something that can be rendered
 */

export function transformSchema(
  schema: DereferencedSchemaObject,
  references: ReferenceMap = new Map(),
): DereferencedSchemaObject {
  let copy = { ...schema };

  if (copy.allOf) {
    copy = copy.allOf.reduce(
      (previous, other) =>
        mergeSchemas(previous, transformSchema(other, references), references),
      copy,
    );
    delete copy.allOf;
  }

  // Set type to object if no other type exists
  if (copy.properties) {
    copy.type ??= 'object';
  }

  return copy;
}
